import React from 'react';
import { alpha, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';

import Main from 'layouts/Main';
import Container from 'components/Container';
import {
  About,
  AboutBottom,
  Features,
  Hero,
  Integrations,
  Jobs,
  News,
  Pricings,
  Reviews,
  Team,
  Trucking,
  Video,
  Intro,
  SalesAbout,
  Services,
  SecondBenefits,
  GetStarted,
  ThirdBenefits
} from './components';

const Logistics = () => {
  const theme = useTheme();

  return (
    <Main>
      <Box
        bgcolor={'alternate.main'}
        sx={{
          position: 'relative',
          '&::after': {
            position: 'absolute',
            content: '""',
            width: '30%',
            zIndex: 1,
            top: 0,
            left: '5%',
            height: '100%',
            // backgroundSize: '18px 18px',
            //backgroundImage: 'https://cms.cognijiwa.ai/assets/d9e33a91-58f4-4f05-a778-856001fec7da.png',
            // backgroundImage: `radial-gradient(${alpha(
            //   theme.palette.primary.dark,
            //   0.4,
            // )} 20%, transparent 20%)`,
            // opacity: 0.2,
          },
        }}
      >
        <Box position={'relative'} zIndex={3} sx={{backgroundImage:'url(https://cms.cognijiwa.ai/assets/d9e33a91-58f4-4f05-a778-856001fec7da.png)'}}>
          <Hero />
        </Box>
      </Box>
      <Container>
        <Intro />
      </Container>
      <Container paddingTop={'0 !important'}>
            <SalesAbout />
      </Container>
      <Container paddingTop={'0 !important'}>
        <Services />
      </Container>
      {/* <Container>
        <Trucking />
      </Container> */}
      <Container paddingTop={'0 !important'}>
        <Trucking />
      </Container>
      <Container paddingTop={'0 !important'}>
        <SecondBenefits />
      </Container>
      {/* <About /> */}
      <Container paddingTop={'0 !important'}>
        {/* <Features /> */}
        <ThirdBenefits />
      </Container>
      <Container paddingTop={'0 !important'}>
        <GetStarted />
      </Container>
      {/* <Box bgcolor={'alternate.main'}>
        <Container>
          <News />
        </Container>
      </Box> */}
      {/* <Box bgcolor={'#11092d'}>
        <Container>
          <Integrations />
        </Container>
      </Box>
      <Container>
        <Team />
      </Container> */}
      {/* <Video /> */}

      {/* <Container>
        <Pricings />
      </Container> */}
      {/* <Box bgcolor={'alternate.main'}>
        <Container>
          <Jobs />
        </Container>
      </Box> */}
      {/* <Container>
        <AboutBottom />
      </Container> */}
      {/* <Box bgcolor={'primary.main'}>
        <Container>
          <Reviews />
        </Container>
      </Box> */}
    </Main>
  );
};

export default Logistics;
