import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const mock = [
  // {
  //   logo: 'https://assets.maccarianagency.com/svg/logos/slack.svg',
  //   name: 'Slack',
  // },
  // {
  //   logo: 'https://assets.maccarianagency.com/svg/logos/mailchimp.svg',
  //   name: 'Mailchimp',
  // },
  // {
  //   logo: 'https://assets.maccarianagency.com/svg/logos/dropbox.svg',
  //   name: 'Dropbox',
  // },
  // {
  //   logo: 'https://assets.maccarianagency.com/svg/logos/google-drive.svg',
  //   name: 'Google Drive',
  // },
  // {
  //   logo: 'https://assets.maccarianagency.com/svg/logos/google-ad-manager.svg',
  //   name: 'Google Ad Manager',
  // },
  // {
  //   logo: 'https://assets.maccarianagency.com/svg/logos/atlassian.svg',
  //   name: 'Atlassian',
  // },
];

const Intro = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box>
      <Box marginBottom={2} marginTop={2}>
        {/* <Typography
          sx={{
            textTransform: 'uppercase',
            fontWeight: 'medium',
          }}
          gutterBottom
          color={'secondary'}
          align={'center'}
        >
          
        </Typography> */}
        <Typography
          variant="h4"
          align={'center'}
          data-aos={'fade-up'}
          gutterBottom
          sx={{
            fontWeight: 700,
          }}
        >
          What is Cogni for Sales?
        </Typography>
        <Typography
          variant="h6"
          align={'center'}
          color={'text.secondary'}
          data-aos={'fade-up'}
        >
          Cogni for Sales is an AI tool that intelligently brings together customer data from your CRM and other platforms. 
        </Typography>

        {/* <Box
          display="flex"
          flexDirection={{ xs: 'column', sm: 'row' }}
          alignItems={{ xs: 'stretched', sm: 'flex-start' }}
          justifyContent={'center'}
          marginTop={2}
        >
          <Box
            component={Button}
            variant="outlined"
            color="primary"
            size="large"
            marginTop={{ xs: 2, sm: 0 }}
            marginLeft={{ sm: 0 }}
            fullWidth={isMd ? false : true}
            href="/pricing"
          >
            See pricing
          </Box>
        </Box> */}
      </Box>
      {/* <Grid
        container
        spacing={0}
        sx={{
          maxWidth: 800,
          margin: '0 auto',
        }}
        data-aos="fade-up"
      >
        {mock.map((item, index) => (
          <Grid
            item
            container
            key={index}
            xs={4}
            direction={index < 3 ? 'row' : 'row-reverse'}
          >
            <Grid item xs={6}>
              <Avatar
                src={item.logo}
                sx={{
                  width: { xs: 60, md: 80 },
                  height: { xs: 60, md: 80 },
                  padding: 2,
                  boxShadow: 4,
                  marginTop: 1,
                }}
                data-aos="zoom-in"
                data-aos-once="false"
              />
            </Grid>
            <Grid item xs={6}></Grid>
          </Grid>
        ))}
      </Grid> */}
    </Box>
  );
};

export default Intro;
